import {
  ChatMessageBubble,
  ChatMessageBubbleType,
  ChatMessageError,
  ChatMessageErrorInlineButton,
  ChatMessageFooter,
  ChatMessageInput,
  ChatMessageList,
  ChatMessageTypingIndicatorBubble,
} from '@amzn/stencil-react-chat-ui';
import { Text } from '@amzn/stencil-react-components/text';
import React, { ChangeEventHandler, useContext, useEffect, useRef, useState } from 'react';
import { DATA_TEST_ID_CHAT_PAGE_SCREEN } from 'src/constants/TestIDConstants';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { ChatSessionContext } from 'src/contextProviders/ChatSessionContext';
import { ChatMessageContainer } from 'src/components/ChatMessage';
import { ChatMessage } from 'src/models/ChatMessage';
import { Feedback } from 'src/components/Feedback';
import { ContentType } from 'src/constants/common';
import { ConnectionStatus } from 'src/models/ConnectChatSession';
import { Link } from '@amzn/atoz-web';
import CustomMessageFeedback from 'src/components/CustomMessageFeedback/CustomMessageFeedback';
import { submitFeedback } from 'src/helpers/feedbackMetricHelper';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { BinaryRatingData } from '@amzn/stencil-react-components/feedback';
import { SessionEndModal } from 'src/components/SessionEnd/SessionEndModal';
import { TermsAndConditionsModal } from 'src/components/TermsAndConditionsModal/TermsAndConditionsModal';
import { MessageRequest } from 'src/models/ChatSessionManager';

export interface ChatPageProps {
  employeeId: string;
  showSessionEndModal: boolean;
  setShowSessionEndModal: React.Dispatch<React.SetStateAction<boolean>>;
  onNewChatSessionClickHandler: () => void;
}

const CHAT_MESSAGE_MAX_MESSAGE_LENGTH = 1000;
const ChatPage = ({
  employeeId,
  showSessionEndModal,
  setShowSessionEndModal,
  onNewChatSessionClickHandler,
}: ChatPageProps) => {
  const katalLogger = new KatalLoggerClient();
  const [userChatInput, setUserChatInput] = useState<string>('');
  const { chatMessageList, sendMessage, connectionStatus, chatSessionManager } = useContext(ChatSessionContext);

  const resetChatMessageInput = () => {
    setUserChatInput('');
  };

  const shouldShowErrorBubble = (message: ChatMessage) =>
    message.type === ChatMessageBubbleType.USER && message.hasError;

  const isMessageResponseLoading =
    connectionStatus === ConnectionStatus.SENT_MESSAGE || connectionStatus === ConnectionStatus.SENDING_MESSAGE;
  const isSubmitMessageDisabled =
    isMessageResponseLoading ||
    connectionStatus !== ConnectionStatus.CONNECTED ||
    userChatInput.trim().length <= 0 ||
    userChatInput.length > CHAT_MESSAGE_MAX_MESSAGE_LENGTH;

  const handleOnMessageChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target: { value } }) => {
    setUserChatInput(value);
  };

  const handleMessageSubmit = async () => {
    if (isSubmitMessageDisabled || !sendMessage) {
      return;
    }
    resetChatMessageInput();
    await sendMessage({
      contentType: ContentType.APPLICATION_JSON,
      messageData: {
        text: userChatInput,
      },
    });
  };

  const handleRetry = async (message?: string) => {
    if (!sendMessage) {
      return;
    }
    await sendMessage({
      contentType: ContentType.APPLICATION_JSON,
      messageData: {
        text: message || '',
      },
    });
  };

  const renderChatMessagesWindow = () => {
    return (
      <ChatMessageList data-test-id={DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_MESSAGE_LIST}>
        {chatMessageList.map((chatMessage, index) => (
          <ChatMessageBubble
            key={chatMessage.messageId}
            type={chatMessage.type}
            dataTestId={`${DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_MESSAGE_BUBBLE_PREFIX}-${index}`}
          >
            <ChatMessageContainer
              chatMessage={chatMessage}
              employeeId={employeeId}
              setShowFeedbackModal={setShowFeedbackModal}
            />
            {shouldShowErrorBubble(chatMessage) && (
              <ChatMessageError dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_MESSAGE_ERROR}>
                <Row gridGap="S100">
                  <Text>Failed to send</Text>
                  <ChatMessageErrorInlineButton
                    dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.RETRY_SEND_MESSAGE_BUTTON}
                    onClick={() => {
                      handleRetry(chatMessage.message);
                    }} // TODO: add interaction functionality
                  >
                    Try asking again
                  </ChatMessageErrorInlineButton>
                </Row>
              </ChatMessageError>
            )}
            {chatMessage.showFeedback && <Feedback chatMessage={chatMessage} />}
          </ChatMessageBubble>
        ))}
        {isMessageResponseLoading && <ChatMessageTypingIndicatorBubble indicatorAriaLabel={'Assistant is Typing'} />}
      </ChatMessageList>
    );
  };
  const onFeedbackChange = (feedback: BinaryRatingData) => {
    setShowFeedbackModal(false);
    submitFeedback(katalLogger, feedback, chatSessionManager!.contactId);
  };
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const [showTnCModal, setShowTnCModal] = useState<boolean>(false);
  return (
    <>
      {showFeedbackModal && (
        <CustomMessageFeedback
          dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_FEEDBACK_CONTAINER}
          onChange={onFeedbackChange}
          showOptionalTextArea={true}
        />
      )}
      {
        <TermsAndConditionsModal
          isOpen={showTnCModal}
          close={() => {
            setShowTnCModal(false);
          }}
          employeeId={employeeId}
        />
      }
      <SessionEndModal
        showSessionEndModal={showSessionEndModal}
        setShowSessionEndModal={setShowSessionEndModal}
        onNewChatSessionClickHandler={onNewChatSessionClickHandler}
        setShowFeedbackModal={setShowFeedbackModal}
      />
      {renderChatMessagesWindow()}
      <ChatMessageFooter>
        <Col gridGap={'S200'} alignItems={'center'}>
          <ChatMessageInput
            value={userChatInput}
            onChange={handleOnMessageChange}
            onSubmit={handleMessageSubmit}
            dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_MESSAGE_INPUT_TEST_ID}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            actionButtonProps={{
              dataTestId: DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_MESSAGE_SUBMIT_BUTTON_TEST_ID,
              disabled: isSubmitMessageDisabled,
              iconAltText: 'Send message',
            }}
            maxLength={CHAT_MESSAGE_MAX_MESSAGE_LENGTH}
          />
          <Text
            dataTestId={DATA_TEST_ID_CHAT_PAGE_SCREEN.CHAT_FOOTER_DISCLAIMER_TEST_ID}
            fontSize={'T50'}
            color={'neutral70'}
            textAlign={'justify'}
          >
            Timehub Assistant uses AI technology to provide its response, please click{' '}
            <Link
              onClick={() => {
                setShowFeedbackModal(true);
              }}
            >
              here
            </Link>{' '}
            to provide any feedback.{' '}
            <Link
              onClick={() => {
                setShowTnCModal(true);
              }}
            >
              Terms and Conditions
            </Link>
          </Text>
        </Col>
      </ChatMessageFooter>
    </>
  );
};

export default ChatPage;
